<template>
  <div id="app">
    <div id='step1' v-if='currentStep == 1'>
      <img src="../images/logo.png" alt="" class='logo'>
      <h2>Сделай коллегам комплимент</h2>
      <h1>Календарь 2024</h1>
      <clipper-upload v-model="photo" class='uploadBtn' @input='loadPhoto'>
        <img src="../images/uploadBtnIcon.svg" alt="">
        Загрузить фото
      </clipper-upload>
    </div>
    <div id='step2' v-if='currentStep == 2'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click='repeat'>
        </div>
        <div>
          <h2>Генератор&nbsp;календаря</h2>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help' @click='showInstruction'>
        </a>
      </div>
      <div class='template'>
        <img src='../images/template.png' alt="">
        <clipper-fixed ref="clipper" :src="photo" :rotate=rotate :wrap-ratio='1.408' :ratio='1.408' :area='100' :bg-color="'rgba(217, 240, 234, 0)'" :border='0'></clipper-fixed>
      </div>
      <template v-if='!creating'>
        <div class='rotate-buttons' v-show='!creating'>
          <a class='rotate-btn left' @click.prevent='rotateImage(-90)' title='Повернуть против часовой стрелки'>&olarr;</a>
          <a class='rotate-btn right' @click.prevent='rotateImage(90)' title='Повернуть по часовой стрелке'>&orarr;</a>
        </div>
        <div class='buttons'>
          <button class='btn' @click.prevent='done' v-if='!creating'>Готово</button>
          <button class='btn' disabled v-else>Создание...</button>

          <clipper-upload v-model="photo" class='btn' @input='loadPhoto' v-show='!creating'>
            Выбрать другое фото
          </clipper-upload>
        </div>
      </template>

      <div class='preloader preloader--gradient' v-else>
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
        
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
      </div>
    </div>
    <div id='step3' v-if='currentStep == 3'>
      <div class='header'>
        <div>
          <img src="../images/logo.png" alt="" class='logo' @click="repeat">
        </div>
        <div>
          <h2>Генератор&nbsp;календаря</h2>
        </div>
        <a @click.prevent='showInstruction'>
          <img src='../images/help.svg' class='help'>
        </a>
      </div>
      <div class='content'>
        <h3>Ваш календарь успешно создан!</h3>
        <viewer :images='[result]' :options='viewerOptions'>
          <img v-for="src in [result]" :key="src" :src="src" class='result'>
        </viewer>
        <div class='buttons'>
          <a @click.prevent='showPrintInstruction' class="print-instruction-btn">
            Инструкция для печати <img src='../images/help.svg' class='help' @click='showPrintInstruction'>
          </a>
          <a :href="result" class='btn' download='Календарь 2024' @click='download'>Скачать</a>
          <button class='btn' v-if="webShareApiSupported" @click.prevent='shareResult'>Поделиться с коллегами<sup>*</sup></button>
          <a href='#' @click.prevent='repeat' class='repeat'>Сделать ещё</a>
        </div>

        <div class='hint' v-if="webShareApiSupported">
          <sup>*</sup> Для сохранения высокого качества изображения рекомендуется делиться через электронную почту либо скачать изображение и отправить его без сжатия любым удобным способом.
        </div>
      </div>
    </div>
    <modal name="instruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a class='modal-close' @click.prevent='hideInstruction'>&times;</a>
      <div class='modal-content'>
        <center><h1>Инструкция</h1></center>
        <p>Данный генератор позволяет создать календарь с Вашим фото на 2024 год.</p>
        <ol>
          <li>Загрузите либо сделайте фото (для мобильных устройств), нажав кнопку <b>&laquo;Загрузить фото&raquo;</b></li>
          <li>
            Отредактируйте положение фото: двигайте, приближайте и&nbsp;отдаляйте так, как считаете нужным.
            <br><i>Для стационарных устройств&nbsp;&mdash; перетаскивание и&nbsp;прокрутка, для мобильных&nbsp;&mdash; перетаскивание и&nbsp;сведение/разведение пальцев.</i>
            <br>Для поворота используйте соответствующие кнопки.
            <br>Можете выбрать другое фото, нажав на&nbsp;кнопку <b>&laquo;Выбрать другое фото&raquo;</b>.
          </li>
          <li>Для завершения нажмите кнопку <b>&laquo;Готово&raquo;</b>.</li>
          <li>Через некоторое время&nbsp;Вы увидите результат, который можно увеличить, нажав на него.</li>
          <li>Вы&nbsp;можете скачать результат, нажав на&nbsp;кнопку <b>&laquo;Скачать&raquo;</b>.</li>
          <li>Также Вы&nbsp;можете поделиться результатом с&nbsp;коллегами и&nbsp;подарить им&nbsp;праздничное настроение уже сейчас, нажав на&nbsp;кнопку <b>&laquo;Поделиться с&nbsp;коллегами&raquo;</b> <i>(Доступно для устройств, поддерживающих такую возможность)</i>.</li>
          <li>Если хотите пересоздать календарь или создать ещё один, нажмите на&nbsp;ссылку <b>&laquo;Сделать ещё&raquo;</b></li>
        </ol>
        <center>
          <b>
            <p>Порадуйте себя и своих коллег в 2024 году!</p>
          </b>
        </center>
        <a href='#' @click.prevent='hideInstruction'>Понятно, приступим!</a>
      </div>
    </modal>

    <modal name="printInstruction" :adaptive='true' :scrollable='true' :height="'auto'">
      <a class='modal-close' @click.prevent='hidePrintInstruction'>&times;</a>
      <div class='modal-content'>
        <center><h1>Инструкция для печати</h1></center>
        <p><strong><u>ПК:</u></strong> Сохраните календарь себе на&nbsp;устройство. Откройте изображение в&nbsp;удобном для вас редакторе, отправьте на&nbsp;печать. Либо выберите пункт &laquo;Печать&raquo; в&nbsp;меню файла.</p>
        <p><strong><u>Мобильные устройства:</u></strong> Чтобы сохранить качество изображения, сгенерируйте календарь в&nbsp;сервисе, затем сохраните его на&nbsp;своем устройстве и&nbsp;отправьте на&nbsp;печать. Размер такого изображения 3508*2480&nbsp;px. Используйте именно этот метод для сохранения четкости календаря при печати.</p>
        <p>Также календарем можно поделиться через мессенджеры. Затем сохранить изображение с&nbsp;мессенджера на&nbsp;свое устройство и&nbsp;отправить на&nbsp;печать. При этом качество изображения будет хуже. Размер изображения 1280*905&nbsp;px. При печати таким методом качество изображения теряется.</p>
        <center>
          <b>
            <p>Порадуйте себя и своих коллег в 2024 году!</p>
          </b>
        </center>
        <a href='#' @click.prevent='hidePrintInstruction'>Понятно, приступим!</a>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import 'normalize.css';
import { clipperFixed, clipperUpload, clipperRange } from 'vuejs-clipper';
import "vuejs-clipper/dist/vuejs-clipper.css";
import 'viewerjs/dist/viewer.css';
import 'vue-js-modal/dist/styles.css';

export default {
  components: {
    clipperFixed,
    clipperUpload,
    clipperRange
  },
  data: function () {
    return {
      currentStep: 1,
      photo: null,
      rotate: 0,
      creating: false,
      result: null,
      viewerOptions: { "inline": false, "button": true, "navbar": false, "title": false, "toolbar": false, "tooltip": false, "movable": false, "zoomable": true, "rotatable": false, "scalable": false, "transition": false, "fullscreen": false, "keyboard": false }
    }
  },
  created: function() {
    axios.defaults.headers.common["X-CSRF-Token"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  },
  methods: {
    loadPhoto: function(_event) {
      this.currentStep = 2;
      if (this.$metrika) this.$metrika.reachGoal('UPLOAD_CLD');
    },
    done: function() {
      let $this  = this;
      // const canvas = this.$refs.clipper.clip();//call component's clip method
      // this.resultURL = canvas.toDataURL("image/jpeg", 1);

      this.creating = true;

      if (this.$metrika) this.$metrika.reachGoal('START_CREATE_CLD');

      axios.post('/create_cld', {
        image: this.$refs.clipper.clip().toDataURL("image/png", 1)
      })
      .then(function(response) {
        $this.result = response.data.image;
        $this.currentStep = 3;
        if ($this.$metrika) $this.$metrika.reachGoal('CREATED_CLD');
      })
      .finally(() => $this.creating = false);
    },
    shareResult: async function() {
      const blob = await (await fetch(this.result)).blob();
      const file = new File([blob], 'fileName.jpg', { type: blob.type });
      navigator.share({
        title: 'Уважаемые коллеги, от души поздравляем вас с предстоящими праздниками! Создайте свой поздравительный календарь по ссылке https://bnphrmgroup.ru/cld. Сделайте приятно своим близким!',
        // url: 'https://bnphrmgroup.ru',
        files: [file],
      })
      if (this.$metrika) this.$metrika.reachGoal('START_SHARE_CLD');
    },
    repeat: function() {
      this.currentStep = 1;
      this.photo = null;
      this.rotate = 0;
      this.creating = false;
      this.result = null;
      if (this.$metrika) this.$metrika.reachGoal('REPEAT_CLD');
    },
    showInstruction() {
      this.$modal.show('instruction');
    },
    hideInstruction() {
      this.$modal.hide('instruction')
    },

    showPrintInstruction() {
      this.$modal.show('printInstruction');
    },
    hidePrintInstruction() {
      this.$modal.hide('printInstruction')
    },

    rotateImage(angle) {
      this.rotate += angle;
    },
    download() {
      if (this.$metrika) this.$metrika.reachGoal('DOWNLOAD_CLD');
    }
  },
  computed: {
    webShareApiSupported: function() {
      return navigator.canShare
    }
  }
  
}
</script>

<style scoped lang='scss'>
#app {
  font-family: Futura PT Book;
  height: 100%;
}
#step1 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  flex-direction: column;

  height: 100%;

  .logo {
    width: 258px;
    // height: 159px;
    padding-bottom: 10px;
    cursor: pointer;

    @media (max-width: 992px) {
      width: 189px;
      // height: 113px;
    }
  }

  h1, h2 {
    margin: 0;
  }

  h2 {
    font-size: 46px;
    line-height: 59px;

    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }

  h1 {
    font-size: 72px;
    line-height: 92px;

    @media (max-width: 992px) {
      font-size: 32px;
      line-height: 41px;
    }
  }

  .uploadBtn {
    margin-top: 69px;

    width: 311px;
    height: 78.5px;

    background: #FFFFFF;
    border: 2px solid #4A7EBD;
    box-sizing: border-box;
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;

    text-align: center;

    color: #4A7EBD;

    cursor: pointer;
    
    img {
      padding-right: 20px;
    }

    @media (max-width: 992px) {
      width: 228px;
      height: 72px;

      font-size: 20px;
      line-height: 26px;
    }

  }
}

#step2, #step3 {
  height: calc(100vh - 60px);
  padding: 30px;

  @media (max-width: 992px) {
    padding: 0;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    @media (max-width: 992px) {
      margin-bottom: 0;
    }

    .logo {
      height: 65px;
      cursor: pointer;
      @media (max-width: 992px) {
        height: 40px;
        padding: 15px;
      }
    }


    & > * {
      flex-basis: 155px;
      &:last-child {
        text-align: right;
      }
    }

    .help {
      cursor: pointer;
    }
    
    @media (max-width: 992px) {
      & > div {
        flex-basis: 155px;
        &:nth-child(2) {
          display: none;
        }
      }

      .help {
        margin-right: 15px;
      }
    }

    

    // &:after {
    //   content: '';
    //   width: 83%;
    //   display: inline-block;
    //   height: 1px;
    //   background: #000;
    //   position: absolute;
    //   right: 0;
    //   left: 0;
    //   margin: 0 auto;
    //   bottom: 0;
    // }
  }
}

#step2 {

  .range {
    max-width: 960px;
    width: 67%;
    margin: 0 auto;
  }

  .rotate-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .rotate-btn {
      width: 39px;
      height: 39px;
      font-size: 25px;
      background: rgba(74, 126, 189, 1);
      cursor: pointer;
      color: white;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }

      &.left {
        transform: rotate(-90deg);
      }

      &.right {
        transform: rotate(90deg);
      }
    }
  }
  
  .template {
    position: relative;
    // max-width: 960px;
    // width: 67%;
    width: 877px;
    margin: 0 auto;
    img {
      position: relative;
      max-width: 100%;
    }

    .js-clipper-fixed {
      position: absolute;
      // top: 15.52%;
      // left: 15.52%;
      // width: 68.96%;
      // height: 68.95%;

      // top: 95.25px;
      // left: 136px;
      // width: 605.75px;
      // height: 429.5px;

      top: 91px;
      left: 178px;
      width: 523px;
      height: 372px;
    }

    @media (max-width: 992px) {
      width: 100%;
      img {
        display: none;
      }
      .js-clipper-fixed {
        position: relative;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px;
      background: rgba(74, 126, 189, 1);
      cursor: pointer;
      color: white;
      margin-right: 10px;
      text-align: center;
      border-radius: 5px;
      width: 200px;
      &:last-child {
        margin-right: 0;
      }
      &:disabled {
        opacity: 0.5;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

#step3 {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-size: 36px;
    line-height: 46px;
    margin-top: 0;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  h4 {
    font-size: 36px;
    line-height: 46px;
    margin: 0 0 10px 0;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 20px;
      line-height: 25px;
    }
  }
  img.result {
    max-width: 500px;
    width: 100%;
    cursor: pointer;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
    .btn {
      box-sizing: border-box;
      border: none;
      padding: 10px 10px;
      background: rgba(74, 126, 189, 1);
      cursor: pointer;
      color: white;
      margin-bottom: 20px;
      text-align: center;
      text-decoration: none;
      border-radius: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media (max-width: 992px) {
      flex-direction: column;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      .btn {
        margin: 0 0 10px 0;
        padding: 10px;
        width: 60%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .repeat {
    color: rgba(74, 126, 189, 1);
    text-align: center;
  }
}

.modal-content {
  padding: 20px;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  text-align: justify;
  ol {
    padding-left: 20px;
  }
  a {
    display: block;
    text-align: center;
    color: rgba(74, 126, 189, 1);
  }
}

.hint {
  max-width: 450px;
  width: 90%;
  text-align: justify;
  opacity: 0.5;
  padding-top: 15px;
  font-size: 75%;
  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 1px;
    background: black;
    margin-bottom: 5px;
  }
}

.modal-close {
  cursor: pointer;
  position: absolute;
  font-size: 26px;
  line-height: 26px;
  width: 26px;
  padding: 5px;
  right: 10px;
  top: 10px;
  text-align: center;
  background: white;
  border-radius: 5px;
  border: 1px solid black;
}

$preloaderMainColor: #0053a6;
$preloaderSecondColor: #E85412;
$preloaderWidth: 55px;
$preloaderHeight: $preloaderWidth;
/*$preloaderAnimationDuration: 1.3s;
$preloaderAnimationDelay: 30; //ms*/
/* baidin */
$preloaderAnimationDuration: 1.7s;
$preloaderAnimationDelay: 150; //ms
/* series */
/*$preloaderAnimationDuration: .9s;
$preloaderAnimationDelay: 100; //ms*/

.preloader {
  width: $preloaderWidth;
  height: $preloaderHeight;
  margin: 20px auto;
  overflow: hidden;
  
  div {
    padding: 0;
    margin: 0;
    display: block;
    float: left;
    width: 27%;
    height: 27%;
    margin-right: 9%;
    margin-bottom: 9%;
    background-color: $preloaderMainColor;
    
    &:nth-child(3n){
      margin-right: 0;
    }
    
    span {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0;
      background-color: $preloaderSecondColor;
      content: '';
      animation-duration: $preloaderAnimationDuration;
      animation-iteration-count: infinite;
      animation-timing-function: ease;
    }
    
    @for $i from 1 through 9 {
      &:nth-child(#{$i}) {
        span{
          animation-delay: #{$i * $preloaderAnimationDelay}ms;
        }
      }
    }
  }
}

.preloader--gradient {
  // margin-left: calc($preloaderWidth / 2);
  div {
    span {
      animation-name: gradient;
    }
  }
}

.print-instruction-btn {
  display: flex;
  gap: 10px;
  padding-bottom: 10px;
  align-items: center;
  cursor: pointer;
}

@keyframes gradient {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}
</style>
